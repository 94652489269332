.App {
  font-family: 'Fira Sans', sans-serif !important;
  height: 100vh;

  margin: 0 auto;
  padding: 0;
  max-width: 900px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.Question {
  color: #ffffff;
  font-family: 'Fira Sans ExtraBold', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
}

.btn-action > span {
  /* color: rgb(255, 255, 255); */
  font-size: x-large;
  font-weight: 600;
}

.btn-disabled {
  background-color: #e0e0e0 !important;
}

.btn-secondary:hover {
  color: #ffffff !important;
}

.select-option {
  color: #ff0000;
  font-size: 25px !important;
  font-style: normal !important;
  font-weight: bold !important;
  text-align: center;
}

.section-name {
  font-family: 'Fira Sans', sans-serif !important;
  font-weight: bold !important;
  font-size: 15px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 93vh;
  padding: 10px;
  width: 90%;
}

.wrapper .btn-holder {
  margin-bottom: 10px;
}

.wrapper .content {
  flex: 1;
  width: 100%;
  padding-bottom: 10px;
}

p.Basic-Paragraph {
  color: #000000;
  font-family: 'Minion Pro', serif;
  font-size: 12px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  orphans: 1;
  page-break-after: auto;
  page-break-before: auto;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-transform: none;
  widows: 1;
}
p.ParaOverride-1 {
  margin-bottom: 65px;
  text-align: center;
}
p.ParaOverride-2 {
  -epub-hyphens: none;
  margin-bottom: 10px;
}
p.ParaOverride-3 {
  text-align: center;
}
span.CharOverride-1 {
  color: #d2232a;
  font-family: 'DM Sans', sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: bold;
}
span.CharOverride-2 {
  color: #ffffff;
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
}
span.CharOverride-3 {
  color: #ffffff;
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}
span.CharOverride-4 {
  color: #d2232a;
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}

img._idGenObjectAttribute-1 {
  height: 25.22px;
  left: 0px;
  width: 25.2px;
}

._idContainerImage {
  text-align: right;
  padding-right: inherit;
}

.paperPadding {
  padding: 10px;
}

.MuiDropzoneArea-root {
  border-color: #000000;
}

div.Basic-Graphics-Frame {
  border-color: #000000;
  border-width: 1px;
  border-style: solid;
}

div.Basic-Text-Frame {
  border-style: solid;
}

p.Basic-Paragraph {
  color: #000000;
  font-family: 'Minion Pro', serif;
  font-size: 12px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  orphans: 1;
  page-break-after: auto;
  page-break-before: auto;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-transform: none;
  widows: 1;
}

span.CharOverride-5 {
  color: #d2232a;
  font-family: 'Fira Sans Medium', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}


.Heading {
  color: #ffffff;
  font-family: 'Fira Sans ExtraBold', sans-serif;
  font-size: 43px;
  font-style: normal;
  font-weight: 800;
  text-align: center;
}